<template>
<layout>
  <template slot="content">
    <div class="container-fluid tms-dashboard">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-calendar-multiple-check icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-info">{{ resume.events}}</h3>
                  <p class="">Tareas pendientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-account-multiple icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class=" text-info">{{ resume.clients}}</h3>
                  <p class="">Clientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-folder-multiple icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-info">{{ resume.records}}</h3>
                  <p class="">Expedientes activos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-file-multiple icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-info">{{ resume.incoming >= 0 ? resume.incoming : 0 | formatPrice}}</h3>
                  <p class="">Cobros Pendientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-chart-areaspline icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-info">{{ resume.payments | formatPrice}}</h3>
                  <p class="">Ingresos recientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid-margin stretch-card">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-3">
                  <i class="mdi mdi-coin icon-lg text-info"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-info">{{ resume.expenses | formatPrice }}</h3>
                  <p class="">Gastos recientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  methods: {
    ...mapActions('Dashboard', ['getResume'])
  },
  computed: {
    ...mapGetters('Dashboard', ['resume'])
  },
  mounted () {
    this.getResume()
  },
  filters: {
    formatPrice (value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      return formatter.format(value)
    }
  },
  components: {
    Layout
  }
}
</script>
